import React from 'react'

const Settings = () => {
    return (
        <div className="text-2xl font-bold pb-2 mb-5  border-b border-b-gray-500 ">
            Settings
        </div>
    )
}

export default Settings;